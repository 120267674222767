// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("@nathanvda/cocoon")    

require("jquery")
require("jquery-ui")
require("jquery-validation")
require("jquery-ui-touch-punch"); // Import jQuery UI Touch Punch after jQuery UI
require("/app/assets/stylesheets/custom.css")
require("/app/assets/stylesheets/sidebar.css")
require("/app/assets/stylesheets/eligiblity.css")

// Tailwind CSS & Other
import "stylesheets/application"
import "jquery-ui-dist/jquery-ui.min.css";

import ApexCharts from 'apexcharts';
window.ApexCharts = ApexCharts;

// Stimulus controllers
import "controllers"
// Other JS
import "src/components/signature-pad"
import "src/sidebar"

// Alpine.js
import "alpinejs"

// app/javascript/src/font-awesome.js
import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';

// import 'line-awesome/css/line-awesome.min.css';
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
