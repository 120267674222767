import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
  }

  toggleSidebar() {
    const iOpxSidebar = document.querySelector('aside#i-opx-sidebar');
    const iOpxSidebarBackdrop = document.querySelector('div#i-opx-sidebar-backdrop');

    iOpxSidebar.classList.toggle('hidden');
    iOpxSidebarBackdrop.classList.toggle('hidden');
  }
}
