// app/javascript/controllers/sidebar_controller.js
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['submenu'];

  toggleSubmenu(event) {
    event.preventDefault();
    const submenu = event.currentTarget.querySelector('.submenu');
    submenu.classList.toggle('hidden');
  }
}