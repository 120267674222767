import SignaturePad from "signature_pad/dist/signature_pad";

function resizeCanvas(canvas) {
  var ratio =  Math.max(window.devicePixelRatio || 1, 1);
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext("2d").scale(ratio, ratio);
}

$(document).on("turbolinks:load", function() {

  $("div.signature-pad").each(function() {
    var canvas = $( this ).find("canvas").get( 0 )
    if (canvas) {
      canvas.height = canvas.offsetHeight;
      canvas.width = canvas.offsetWidth;
      window.onresize = resizeCanvas(canvas);
      resizeCanvas(canvas);

      var signaturePad = new SignaturePad(canvas)
      $( this ).find("a.sp-clear").on("click", function(){
        signaturePad.clear()
        $( this ).closest(".signature-pad").find("input.sp-input").val("")
      })
      signaturePad.onEnd = function(){
        var $input = $( this.canvas ).closest(".signature-pad").find("input.sp-input")
        $input.val(this.toDataURL())
      }
    }
  })
});
