document.addEventListener("turbolinks:load", function() {

  $("li.sb-nav-menu-item").each(function(){
    if (!$(this).find("div.sub-nav ul li").length) {
      $(this).addClass("hidden");
    }
  })

  $("li.sb-nav-menu-item")
    .on("mouseover touchstart", function() {
      $subnav = $(this).closest("li").find("div.sub-nav")
      if ($(this).hasClass("sb-nav-menu-item")) {
        $subnav.css("top", this.offsetTop)
      }
      $subnav.removeClass("hidden")
    })
    .on("mouseout", function() {
      $(this).closest("li").find("div.sub-nav").addClass("hidden")
    })

  $("li.sb-nav-menu-item a").on("click", function(event) {
    if ($(this).attr("href") == "#") {
      event.preventDefault()
    }
  })

})
