import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    console.log('Hello Controller Connected');
  }

  toggleSidebar () {
    console.log('Sidebar Toggled');
  }
}
